import React from "react"
import { graphql } from "gatsby"

import Header from "econify/components/Header"
import TheLoopList from "econify/routes/TheLoop/TheLoopList"
import { BroadcastSummary } from "econify/routes/TheLoop/TheLoopList/TheLoopList"
import Footer from "econify/components/Footer"

type TheLoopListPageProps = {
  data: {
    allConvertKitBroadcastSummary: {
      nodes: BroadcastSummary[]
    }
  }
}

const TheLoopListPage: React.FC<TheLoopListPageProps> = ({ data }) => {
  const broadcastSummaries = data?.allConvertKitBroadcastSummary?.nodes ?? []
  return (
    <>
      <Header />
      <TheLoopList broadcastSummaries={broadcastSummaries} />
      <Footer />
    </>
  )
}

export default TheLoopListPage

export const query = graphql`
  query {
    allConvertKitBroadcastSummary(sort: { order: DESC, fields: createdAt }) {
      nodes {
        id
        createdAt
        subject
        children {
          ... on ConvertKitBroadcast {
            id
            subject
            publishedAt
            createdAt
          }
        }
      }
    }
  }
`

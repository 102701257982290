import React from "react"
import { Link } from "gatsby"

import TheLoopHeading from "econify/routes/TheLoop/TheLoopHeading"
import { Broadcast } from "econify/routes/TheLoop/Detail/TheLoopDetail"
import * as styles from "./TheLoopList.module.css"

interface TheLoopListProps {
  broadcastSummaries: BroadcastSummary[]
}

export interface BroadcastSummary {
  id: string
  createdAt: string
  subject: string
  children: Broadcast[]
}

const TheLoopList: React.FC<TheLoopListProps> = ({ broadcastSummaries }) => (
  <div className={styles.container}>
    <TheLoopHeading />
    {broadcastSummaries.map(summary => {
      const broadcast = summary?.children?.[0]
      if (!broadcast?.id || !broadcast?.publishedAt) {
        return null
      }

      return (
        <div key={summary.id}>
          <Link to={`/theloop/${broadcast.id}`}>
            <h3>{summary.subject}</h3>
          </Link>
          <div>{new Date(broadcast.publishedAt).toDateString()}</div>
          <div className={styles.line} />
        </div>
      )
    })}
  </div>
)

export default TheLoopList
